import React from "react";
import { Field, Formik, FormikHelpers } from "formik";
import { Input } from "../../fields/Input";
import appService from "../../../services/app.service";
import { Loading } from "../../Loading";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { IArticle } from "../../../core/catering.types";
import { NavigationButton } from "../../fields/NavigationButton"
import { Select } from "../../fields/Select"
import { Textarea } from "../../fields/Textarea";
import { UploadModalBox } from "../../images/UploadModalBox"

export const ArticleDetails: React.FunctionComponent<{ id?: string }> = (props) => {
    const { id } = props;
    const backButton = "/admin/articles"
    const isNew = id === undefined || id === null || id === "";
    const title = <h1 className="title">Artikel</h1>
    const history = useHistory();
    const [data, setData] = React.useState<{
        isLoading: boolean;
        data: IArticle;
    }>({
        isLoading: true,
        data: {},
    });

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("*"),
        unit: Yup.string().required("*"),
        delivery: Yup.string().required("*")
    });

    React.useEffect(() => {
        const fetchData = async () => {
            const data = await appService.getArticle(id);
            setData({
                isLoading: false,
                data: data,
            });

        };

        if (isNew) {
            setData({
                isLoading: false,
                data: { name: '', description: '', unit: "Stück", delivery: "Catering" },
            });
        } else {
            fetchData();
        }
    }, []);


    const onSubmit = (
        values: Readonly<IArticle | undefined>,
        formikHelpers: FormikHelpers<Readonly<IArticle>>
    ) => {
        const data = values;

        const submit = async () => {
            if (data !== undefined && data !== null) {
                appService.saveArticle(data).then((modulesResults) => {
                    history.push(`${backButton}`)
                }).catch(() => {
                    alert("Es gab einen Fehler beim Speichern");
                });
            }
        };

        submit();
    };

    const unitOption = [{ key: "Stück", text: "Stück" }, { key: "Flasche", text: "Flasche" }, { key: "Person", text: "Person" }];
    const deliveryOption = [{ key: "Catering", text: "Catering" }, { key: "Hausdienst", text: "Hausdienst" }];

    //console.log(JSON.stringify(data))
    return (
        <React.Fragment>
            <div className="section defaultSection">
                <Loading isLoading={data.isLoading}></Loading>
                {data.isLoading === false && (
                    <Formik
                        initialValues={data.data}
                        onSubmit={onSubmit}
                        validateOnMount={true}
                        validationSchema={validationSchema}
                        enableReinitialize
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            dirty,
                            setFieldValue,
                        }) => (
                            <React.Fragment>
                                <form onSubmit={handleSubmit} className="form">
                                    <div className="columns">
                                        <div className="column">{title}</div>
                                        <div className="column button-header">
                                            <button
                                                type="submit"
                                                className={`button is-primary is-small is-pulled-right backAndSaveButton ${isSubmitting ? "is-loading" : ""
                                                    }`}
                                                disabled={isSubmitting || !dirty || !isValid}
                                            >
                                                Speichern
                                            </button>
                                            <NavigationButton title="Zurück" className="button is-small is-pulled-right backAndSaveButton" relativeLocation={backButton} />
                                        </div>
                                    </div>
                                    <div className="columns">
                                        <div className="column is-4">
                                            <div className="photoBox">
                                                <img src={values?.photoUrl} className="is-fullwidth" alt="" />
                                                <div>
                                                    <UploadModalBox label="Image" field="photoUrl" width={600} height={600} setFieldValue={setFieldValue} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="columns is-vcentered">
                                                <div className="column is-half">
                                                    <Field
                                                        name="name"
                                                        label="Name"
                                                        component={Input}
                                                    />
                                                </div>
                                                <div className="column">
                                                    <Field
                                                        name="unit"
                                                        label="Einheit"
                                                        initialValues="Stück"
                                                        options={unitOption}
                                                        component={Select}
                                                    />
                                                </div>
                                                <div className="column">
                                                    <Field
                                                        name="delivery"
                                                        label="Zustellung durch"
                                                        initialValues="Catering"
                                                        options={deliveryOption}
                                                        component={Select}
                                                    />
                                                </div>
                                            </div>
                                            <Field
                                                name="description"
                                                label="Beschreibung"
                                                rows={6}
                                                component={Textarea}
                                            />
                                        </div>
                                    </div>


                                    <div className="button-footer">
                                        <button
                                            type="submit"
                                            className={`button is-primary is-small is-pulled-right backAndSaveButton${isSubmitting ? "is-loading" : ""
                                                }`}
                                            disabled={isSubmitting || !dirty || !isValid}
                                        >
                                            Speichern
                                        </button>
                                        <NavigationButton title="Zurück" className="button is-small is-pulled-right backAndSaveButton" relativeLocation={backButton} />
                                    </div>
                                </form>
                            </React.Fragment>
                        )}
                    </Formik>
                )}
            </div>
        </React.Fragment>
    );
};