import React from "react";
import { Field, FieldArray } from "formik";
import { Input } from "../../fields/Input";
import { IArticle, IArticleOrder, IArticleOrderCategory, IArticleOrderSubcategory, ISubcategory } from "../../../core/catering.types";
import "./OrderSubCategory.css"
import { ModalBox } from "../../fields/ModalBox";

export const OrderSubCategory: React.FunctionComponent<{ isDisabled: boolean, category: IArticleOrderCategory, namespace: string, allArticles: IArticle[], hideUnordered?: boolean }> = (props) => {
    const { isDisabled, category, namespace, allArticles, hideUnordered } = props;
    const formatter = new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' });
    const [selectedArticle, setSelectedArticle] = React.useState<IArticleOrder>(null);

    const GetArticleName = (id) => {
        const item = allArticles.find(i => i.key === id);
        return item !== undefined ? item.name : "";
    };
    const GetArticleUnit = (id) => {
        const item = allArticles.find(i => i.key === id);
        return item !== undefined ? item.unit : "";
    };
    const GetArticleDescription = (id) => {
        const item = allArticles.find(i => i.key === id);
        return item !== undefined ? item.description : "";
    };
    const GetArticlePhotoUrl = (id) => {
        const item = allArticles.find(i => i.key === id);
        return item !== undefined ? item.photoUrl : undefined;
    };

    if (hideUnordered === true) {
        var toShow = false;
        (category.subcategories.length !== 0 && category.subcategories.map((subcat) => (
            (subcat.articles.length !== 0 && subcat.articles.map((art) => (
                (art.amount !== 0 && (
                    toShow = true
                ))
            )))
        )))

        if (toShow === false)
            return <React.Fragment></React.Fragment>
    }

    return (
        <React.Fragment>
            {selectedArticle !== null && (
                <ModalBox title={GetArticleName(selectedArticle.articleId)} isActive={selectedArticle !== null} hideConfirm={true} onCloseClick={() => setSelectedArticle(null)}>
                    <div className="columns">
                        {GetArticlePhotoUrl(selectedArticle.articleId) !== undefined && (
                            <div className="column is-4">
                                <img src={GetArticlePhotoUrl(selectedArticle.articleId)} className="is-fullwidth" alt="" />
                            </div>
                        )}
                        <div className="column">
                            <p>{GetArticleDescription(selectedArticle.articleId)}</p>
                        </div>

                    </div>
                    <br />
                    <p><b>Preis</b>: {formatter.format(selectedArticle.price)} / {GetArticleUnit(selectedArticle.articleId)}</p>
                </ModalBox>
            )}

            {hideUnordered !== true ? (
                <table className="table is-fullwidth orderTable">
                    <thead>
                        <tr>
                            <th>Artikel</th>
                            <th style={{ "width": "15%" }}>Einheit</th>
                            <th style={{ "width": "15%" }}>Anzahl</th>
                            <th style={{ "width": "15%" }}>CHF/Einheit</th>
                            <th style={{ "width": "15%" }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {category.subcategories?.map((subCat: IArticleOrderSubcategory, indSubCat: number) => (
                            <React.Fragment key={`SC${indSubCat}`}>
                                <FieldArray
                                    name={`${namespace}.subcategories[${indSubCat}]`}
                                    render={(subcatHelper) => (
                                        <React.Fragment>
                                            <tr style={{ "border": "0px" }}>
                                                <td colSpan={6} style={{ "border": "0px" }}>&nbsp;</td>
                                            </tr>
                                            <tr key={"TRF" + indSubCat}>
                                                <th colSpan={6}>
                                                    <b>{subCat?.name}</b>
                                                </th>
                                            </tr>
                                            {subCat.articles?.map((art: IArticleOrder, indArt: number) => (
                                                <React.Fragment key={`SC${indSubCat}A${indArt}`}>
                                                    <FieldArray
                                                        name={`${namespace}.subcategories[${indSubCat}].articles[${indArt}]`}
                                                        render={(articlesHelper) => (
                                                            <tr>
                                                                <td>
                                                                    {GetArticleName(art.articleId)} {(GetArticleDescription(art.articleId) !== "" || GetArticlePhotoUrl(art.articleId) !== "") && (<button type="button" onClick={(e) => { e.preventDefault(); setSelectedArticle(art) }} className={"button is-small"}><i className={`fas fa-info`} /></button>)}
                                                                </td>
                                                                <td>
                                                                    {GetArticleUnit(art.articleId)}
                                                                </td>
                                                                <td className="has-text-right">
                                                                    <Field
                                                                        type={"number"}
                                                                        className={"input has-text-right"}
                                                                        min={0}
                                                                        disabled={isDisabled}
                                                                        name={`${namespace}.subcategories[${indSubCat}].articles[${indArt}].amount`}
                                                                        component={Input}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    {formatter.format(art.price)}
                                                                </td>
                                                                <td>
                                                                    {formatter.format(art.price * (art.amount ?? 0))}
                                                                </td>
                                                            </tr>)}
                                                    />

                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    )}
                                />
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            ) : (
                <React.Fragment >
                    {category.subcategories?.filter((p) => {
                        var toShow = false;
                        (p.articles.length !== 0 && p.articles.map((art) => (
                            (art.amount !== 0 && (
                                toShow = true
                            ))
                        )))
                        if (toShow === false) {
                            return;
                        }
                        return p;
                    }).map((subCat: IArticleOrderSubcategory, indSubCat: number) => (
                        <React.Fragment key={`SC${indSubCat}`}>
                            <tr style={{ "border": "0px" }}>
                                <td colSpan={6} style={{ "border": "0px" }}>&nbsp;</td>
                            </tr>
                            <tr key={"TRF" + indSubCat}>
                                <th colSpan={6}>
                                    <b>{subCat?.name}</b>
                                </th>
                            </tr>
                            {subCat.articles?.filter((p) => {
                                if (p.amount === 0)
                                    return;

                                return p;
                            }).map((art: IArticleOrder, indArt: number) => (
                                <tr key={`SC${indSubCat}A${indArt}`}>
                                    <td>
                                        {GetArticleName(art.articleId)} {(GetArticleDescription(art.articleId) !== "" || GetArticlePhotoUrl(art.articleId) !== "") && (<button type="button" onClick={(e) => { e.preventDefault(); setSelectedArticle(art) }} className={"button is-small"}><i className={`fas fa-info`} /></button>)}
                                    </td>
                                    <td>
                                        {GetArticleUnit(art.articleId)}
                                    </td>
                                    <td className="has-text-right">
                                        {art.amount}
                                    </td>
                                    <td>
                                        {formatter.format(art.price)}
                                    </td>
                                    <td>
                                        {formatter.format(art.price * (art.amount ?? 0))}
                                    </td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            )}

        </React.Fragment >
    )
};