import "./App.css";
import "./App-default.css"

import React, { useState } from 'react';
import { Route, Switch } from "react-router-dom";
import { IAppProps } from "./core/app.types";
import { Auth } from "./services/auth/auth.service";
import { Navigation } from "./components/Navigation"
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { registerLocale } from "react-datepicker";
import { Landing } from "./components/Landing"
import de from "date-fns/locale/de";
import "react-datepicker/dist/react-datepicker.css";
import { Loading } from "./components/Loading";
import appService from "./services/app.service"
import { IAppRoles, IArticle, IMenu, IServiceException } from "./core/catering.types";
import { MenuDetails } from "./components/config/menues/MenuDetails"
import { MenuOverview } from "./components/config/menues/MenuOverview"
import { ArticleDetails } from "./components/config/articles/ArticleDetails"
import { ArticlesOverview } from "./components/config/articles/ArticlesOverview"
import { OrderDetails } from "./components/config/Orders/OrderDetails"
import { OrderOverview } from "./components/config/Orders/OrderOverview"
import { NoAdminAlert } from "./components/NoAdminAlert";
import { HausdienstOverview } from "./components/config/hausdienst/HausdienstOverview"
import { HausdienstDetails } from "./components/config/hausdienst/HausdienstDetails"
import { ArchiveOverview } from "./components/config/archive/ArchiveOverview"

registerLocale("de", de);

initializeIcons();

export const App: React.FunctionComponent<IAppProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [appRoles, setAppRoles] = useState<IAppRoles>({
    isAdmin: false,
    isFacilityMember: false,
    isMember: true
  });

  const [userDisplayName, SetuserDisplayName] = useState<string>("");
  const [userID, SetuserID] = useState<string>("");

  const fetchData = async () => {
    Auth.initialize(props.Config);
    const auth = Auth.getInstance();

    auth
      .login()
      .then(async () => {
        try {
          const account = auth.getUserAccount();

          if (account.roles !== undefined) {
            const hasFacilityRole = account.roles.indexOf("FacilityManagement") > -1
            const hasAdminRole = account.roles.indexOf("Admin") > -1
            
            SetuserDisplayName(account?.displayName.toString());
            SetuserID(account?.id.toString());

            setAppRoles({
              isMember: true,
              isFacilityMember: hasFacilityRole || hasAdminRole,
              isAdmin: hasAdminRole
            })
          }
        } catch (ex) {
          const message = ex as IServiceException;
          alert(message.message)
        }
        setIsLoading(false);
      })
      .catch((ex) => {
        if (ex.errorCode !== "interaction_in_progress") {
          setIsLoading(false);
        }
      });
  };

  React.useEffect(() => {
    fetchData()
  }, []);

  if (isLoading === true) {
    return <Loading isLoading={isLoading}></Loading>;
  }

  require(`./App-default.css`)

  return (
    <React.Fragment>
      <Navigation isAdmin={appRoles.isAdmin} isFacilityMember={appRoles.isFacilityMember} />
      <div className="container is-fluid">
        <Switch>
          <Route
            path={["/"]}
            exact
            render={(renderProps) =>
              <React.Fragment>
                <Landing />
              </React.Fragment>
            }
          />
          <Route
            path={["/details"]}
            exact
            render={(renderProps) =>
              <React.Fragment>
                <OrderDetails userDisplayName={userDisplayName} />
              </React.Fragment>
            }
          />
          <Route
            path={["/details/:id"]}
            render={(renderProps) =>
              <React.Fragment>
                <OrderDetails id={renderProps.match.params.id} userDisplayName={userDisplayName} />
              </React.Fragment>
            }
          />
          <Route
            path={["/admin/articles"]}
            exact
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isAdmin !== true ? (
                  <NoAdminAlert type="Administratoren" />
                ) : (
                  <ArticlesOverview />
                )}
              </React.Fragment>
            }
          />
          <Route
            path={["/admin/articles/details"]}
            exact
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isAdmin !== true ? (
                  <NoAdminAlert type="Administratoren" />
                ) : (
                  <ArticleDetails />
                )}
              </React.Fragment>
            }
          />
          <Route
            path={["/admin/articles/details/:id"]}
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isAdmin !== true ? (
                  <NoAdminAlert type="Administratoren" />
                ) : (
                  <ArticleDetails id={renderProps.match.params.id} />
                )}
              </React.Fragment>
            }
          />

          <Route
            path={["/admin/menues"]}
            exact
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isAdmin !== true ? (
                  <NoAdminAlert type="Administratoren" />
                ) : (
                  <MenuOverview />
                )}
              </React.Fragment>
            }
          />
          <Route
            path={["/admin/menues/details"]}
            exact
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isAdmin !== true ? (
                  <NoAdminAlert type="Administratoren" />
                ) : (
                  <MenuDetails />
                )}
              </React.Fragment>
            }
          />
          <Route
            path={["/admin/menues/details/:id"]}
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isAdmin !== true ? (
                  <NoAdminAlert type="Administratoren" />
                ) : (
                  <MenuDetails id={renderProps.match.params.id} />
                )}
              </React.Fragment>
            }
          />

          <Route
            path={["/admin/orders"]}
            exact
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isAdmin !== true ? (
                  <NoAdminAlert type="Administratoren" />
                ) : (
                  <OrderOverview redirection="/admin/orders/details" isAdmin={appRoles.isAdmin} />
                )}
              </React.Fragment>
            }
          />
          <Route
            path={["/admin/orders/details"]}
            exact
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isAdmin !== true ? (
                  <NoAdminAlert type="Administratoren" />
                ) : (
                  <OrderDetails redirection="/admin/orders" isAdmin={appRoles.isAdmin} userDisplayName={userDisplayName} />
                )}
              </React.Fragment>
            }
          />
          <Route
            path={["/admin/orders/details/:id"]}
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isAdmin !== true ? (
                  <NoAdminAlert type="Administratoren" />
                ) : (
                  <OrderDetails redirection="/admin/orders" id={renderProps.match.params.id} isAdmin={appRoles.isAdmin} userDisplayName={userDisplayName} />
                )}
              </React.Fragment>
            }
          />

          <Route
            path={["/admin/hausdienst"]}
            exact
            render={(renderProps) =>
              <React.Fragment>
                {(appRoles.isFacilityMember !== true) ? (
                  <NoAdminAlert type="Hausdienst Mitarbeiter" />
                ) : (
                  <HausdienstOverview redirection="/admin/hausdienst/details" />
                )}
              </React.Fragment>
            }
          />
          <Route
            path={["/admin/hausdienst/details"]}
            exact
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isFacilityMember !== true ? (
                  <NoAdminAlert type="Hausdienst Mitarbeiter" />
                ) : (
                  <HausdienstDetails />
                )}
              </React.Fragment>
            }
          />
          <Route
            path={["/admin/hausdienst/details/:id"]}
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isFacilityMember !== true ? (
                  <NoAdminAlert type="Hausdienst Mitarbeiter" />
                ) : (
                  <HausdienstDetails id={renderProps.match.params.id} isAdmin={appRoles.isAdmin} />
                )}
              </React.Fragment>
            }
          />

          <Route
            path={["/admin/archive"]}
            exact
            render={(renderProps) =>
              <React.Fragment>
                {(appRoles.isAdmin !== true) ? (
                  <NoAdminAlert type="Administratoren" />
                ) : (
                  <ArchiveOverview isAdmin={appRoles.isAdmin} redirection="/admin/archive/details" />
                )}
              </React.Fragment>
            }
          />
          <Route
            path={["/admin/archive/details"]}
            exact
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isAdmin !== true ? (
                  <NoAdminAlert type="Administratoren" />
                ) : (
                  <OrderDetails redirection="/admin/archive" id={renderProps.match.params.id} isAdmin={appRoles.isAdmin} userDisplayName={userDisplayName} />
                )}
              </React.Fragment>
            }
          />
          <Route
            path={["/admin/archive/details/:id"]}
            render={(renderProps) =>
              <React.Fragment>
                {appRoles.isAdmin !== true ? (
                  <NoAdminAlert type="Administratoren" />
                ) : (
                  <OrderDetails redirection="/admin/archive" id={renderProps.match.params.id} isAdmin={appRoles.isAdmin} userDisplayName={userDisplayName} />
                )}
              </React.Fragment>
            }
          />

        </Switch>
      </div>
    </React.Fragment>
  );
}

export default App;
