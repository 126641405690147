import React from "react";
import { Field, FieldArray } from "formik";
import { Input } from "../../fields/Input";
import { IArticle, ICategory } from "../../../core/catering.types";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import { Subcategories } from "./Subcategories";
import "./Categories.css"

export const Categories: React.FunctionComponent<{ categories: ICategory[], allArticles: IArticle[] }> = (props) => {
    const { categories, allArticles } = props;

    return (
        <FieldArray
            name="categories"
            render={(arrayHelpers) => (
                <div className="categories">
                    <button className="button is-primary btnCategory"
                        onClick={(e) => {
                            e.preventDefault();
                            arrayHelpers.push({
                                name: "",
                                description: "",
                                subcategories: []
                            });
                        }}
                    ><i className="fas fa-plus-circle" />&nbsp;Überschrift hinzufügen</button>

                    <Tabs>
                        <TabList key={categories?.length}>
                            {categories?.map((cat: ICategory, indCat: number) => (
                                <Tab key={indCat}>
                                    <Field
                                        name={`categories.${indCat}.name`}
                                        component={Input}
                                        suffixControl={
                                            <div className="control">
                                                <button className="button" disabled={indCat === 0} onClick={(e) => { e.preventDefault(); arrayHelpers.move(indCat, indCat - 1); }}><i className="far fa-caret-square-left" /></button>
                                                <button className="button" disabled={indCat > categories?.length - 2} onClick={(e) => { e.preventDefault(); arrayHelpers.move(indCat, indCat + 1); }}><i className="far fa-caret-square-right" /></button>
                                                <button className="button is-danger" onClick={(e) => { e.preventDefault(); if (window.confirm("Soll dieser Eintrag wirklich gelöscht werden?")) { arrayHelpers.remove(indCat); } }}><i className="fas fa-trash" /></button>
                                            </div>
                                        }
                                    />
                                </Tab>
                            ))}
                        </TabList>
                        {categories?.map((cat: ICategory, indCat: number) => (
                            <TabPanel key={indCat}>
                                <Subcategories category={cat} namespace={`categories[${indCat}]`} allArticles={allArticles} />
                            </TabPanel>
                        ))}
                    </Tabs>
                </div>
            )}
        />
    )
};