import React from 'react';
import { NavigationLink } from "../components/fields/NavigationLink"
import Logo from "../img/imagenew.png"


export const Navigation: React.FunctionComponent<{ isAdmin: boolean, isFacilityMember: boolean }> = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { isAdmin, isFacilityMember } = props;
    const toggle = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(false);

    return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item" href="/">
                    <img src={Logo} height="200px"></img>
                </a>
                <a role="button" className="navbar-burger" aria-label="menu" onClick={toggle} aria-expanded="false" data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="mainNavBar" className={`navbar-menu  ${isOpen ? "is-active" : ""}`}>
                <div className="navbar-start" onClick={close} $navbar-item-color>
                    <NavigationLink title="UKKB - Catering" relativeLocation="/" className="navbar-item has-text-light" />
                </div>
                <div className="navbar-end">
                    {props.children}
                    {(isAdmin || isFacilityMember) && (
                        <div className="navbar-item has-dropdown is-hoverable has-shadow">
                            <a className="navbar-link has-text-light" >Verwaltung</a>
                            <div className="navbar-dropdown">
                                <React.Fragment>
                                    {isAdmin && (
                                        <React.Fragment>
                                            <NavigationLink title="Artikel" relativeLocation="/admin/articles" className="navbar-item"/>
                                            <NavigationLink title="Speisekarten" relativeLocation="/admin/menues" className="navbar-item"/>
                                            <NavigationLink title="Bestellungen" relativeLocation="/admin/orders" className="navbar-item"/>
                                            <NavigationLink title="Archiv" relativeLocation="/admin/archive" className="navbar-item"/>
                                        </React.Fragment>
                                    )}
                                    <NavigationLink title="Hausdienst" relativeLocation="/admin/hausdienst" className="navbar-item"/>
                                </React.Fragment>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};
