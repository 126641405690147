export class QueryStringService {
  private static instance: QueryStringService;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {}

  isFrame(): boolean {
    try {
      return window.self !== window.top;
    } catch (ex) {
      return false;
    }
  }

  getUrlParameterByName = (
    name: string,
    url: string | undefined = undefined
  ): string | undefined => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return undefined;
    if (!results[2]) return undefined;
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  };

  public static getInstance() {
    if (
      QueryStringService.instance === null ||
      QueryStringService.instance === undefined
    ) {
      QueryStringService.instance = new QueryStringService();
    }
    return QueryStringService.instance;
  }
}
