import React, { useState } from "react";
import { IOrder } from "../../../core/catering.types"
import appService from "../../../services/app.service";
import { useSortableData } from "../../../services/sortableData.service"
import { NavigationButton } from "../../fields/NavigationButton"
import moment from "moment";
import { SortButton } from "../../SortButton"
import { getStatiClass, getStatusDisplayName } from "../Orders/OrderOverview";


export const HausdienstOverview: React.FunctionComponent<{ redirection?: string, userDisplayName?: string, isAdmin?: Boolean }> = (props) => {
    const { redirection, userDisplayName, isAdmin } = props;
    const detailsRedirection = redirection ?? "/details";
    const [data, setData] = useState<{
        isLoading: boolean,
        data: IOrder[]
    }>({
        isLoading: true,
        data: []
    });


    const fetchData = async () => {

        // until the filter can be set on Filter2 - Containshausdienst
        let data = await appService.getHausdienstOrders();
        setData({ isLoading: false, data: data });
    };

    React.useEffect(() => {
        fetchData()
    }, []);

    const { items, requestSort, sortConfig } = useSortableData(data.data);

    return (
        <div className="section defaultSection">
            <h1 className="title">Bestellungen für den Hausdienst</h1>

            <div className="table-container">
                <table className="table is-fullwidth">
                    <thead>
                        <tr>
                            <th><SortButton property="date" title="Datum" sortConfig={sortConfig} requestSort={requestSort} /></th>
                            <th><SortButton property="titel" title="Titel" sortConfig={sortConfig} requestSort={requestSort} /></th>
                            <th><SortButton property="location" title="Lokation" sortConfig={sortConfig} requestSort={requestSort} /></th>
                            <th><SortButton property="status" title="Status" sortConfig={sortConfig} requestSort={requestSort} /></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item) => (
                            <tr key={item.key}>
                                <td>{moment(item.date).format("DD.MM.yyyy")}</td>
                                <td>{item.title}</td>
                                <td>{item.place}</td>
                                <td className={getStatiClass(item.status)}>{getStatusDisplayName(item.status)}</td>
                                <td className="actions">
                                    <NavigationButton className="button is-primary is-small" relativeLocation={`${detailsRedirection}/${item.key}`}>
                                        <span className="icon is-small">
                                            <i className="fas fa-eye" />
                                        </span>
                                    </NavigationButton>
                                </td>
                            </tr>
                        ))}
                        {items.length === 0 && (
                            <tr>
                                <td colSpan={5}>Keine Bestellungen gefunden</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
