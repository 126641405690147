import 'bulma/css/bulma.css'
import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { IConfig } from './services/auth/auth.types';

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { I18nextProvider } from "react-i18next";

import client_de from "./translations/de/client.json";

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  detection: {
    order: ["querystring", "navigator"],
  },
  resources: {
    de: {
      client: client_de
    }
  },
  react: {
    wait: true,
  },
  fallbackLng: "de",
});

const config: IConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENTID,
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    redirectUri: process.env.REACT_APP_AUTH_REDIRECTURI
  },
  scopes: {
    appScope: process.env.REACT_APP_SCOPES_APP,
    graphScope: process.env.REACT_APP_SCOPES_GRAPH
  },
  endpoints: {
    api: process.env.REACT_APP_ENDPOINT_API
  }

}

const renderApp = (config: IConfig) => {
  ReactDOM.render(
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <App Config={config} />
      </I18nextProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );
}

renderApp(config);
