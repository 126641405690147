import React from "react";
import "./SortButton.css"

export const SortButton: React.FunctionComponent<{ property: string, title: string, sortConfig: any, requestSort(property: string, type?: string), typeName?: string }> = (props) => {
    const { property, title, sortConfig, requestSort, typeName } = props;

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const option = getClassNamesFor(property);
    const classArrow = "arrow " + option;

    return (
        <React.Fragment>
            <a href="#" className="sortButton" onClick={(e) => { e.preventDefault(); requestSort(property, typeName) }}>{title}</a>
            <span className={classArrow} />
        </React.Fragment>
    );
};
