import React, { Fragment } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { IArticle, IArticleOrder, IGeneralSettings, IOrder } from "../../../core/catering.types";
import appService from "../../../services/app.service";
import moment from 'moment';

let n = 0;

const GetDate = () => {
    const currentDate = moment();
    return currentDate.format("DD.MM.yyyy");
}

const DetailsRow = (key: string, val: string) => (
    <View style={styles.RowDetailse}>
        <View style={styles.Col1Details}>
            <Text style={styles.cellDetails}>{key === undefined || key === "" ? " " : key}</Text>
        </View>
        <View style={styles.Col2Details}>
            <Text style={styles.cellDetails}>{val === undefined || val === "" ? " " : val}</Text>
        </View>
    </View>
);

const formatter = new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' });

const AddHeaderRow = (cell1: string, cell2: string, cell3: string, cell4: string, cell5: string, onlyCatering: boolean) => (
    <View style={styles.RowHeader} >
        <View style={styles.ColA}>
            <Text style={styles.cellHeader}>{cell1}</Text>
        </View>
        <View style={styles.ColB}>
            <Text style={styles.cellHeader}>{cell2}</Text>
        </View>
        <View style={styles.ColC}>
            <Text style={styles.cellHeader}>{cell3}</Text>
        </View>
        <View style={styles.ColD}>
            <Text style={styles.cellHeader}>{cell4}</Text>
        </View>
        <View style={styles.ColE}>
            <Text style={styles.cellHeader}>{cell5}</Text>
        </View>
        {(onlyCatering && (
            <View style={styles.ColE}>
                <Text style={styles.cellHeader}>Rückgabe</Text>
            </View>
        ))}
    </View>
);


const AddRow = (cell1: string, cell2: string, cell3: string, cell4: string | number, cell5: string, n: number, onlyCatering: boolean) => (
    <View style={n % 2 ? styles.RowEven : styles.RowOdd} >
        <View style={styles.ColA}>
            <Text style={styles.cell}>  {cell1}</Text>
        </View>
        <View style={styles.ColB}>
            <Text style={styles.cell}>  {cell2}</Text>
        </View>
        <View style={styles.ColC}>
            <Text style={styles.cell}>{cell3}</Text>
        </View>
        <View style={styles.ColD}>
            <Text style={styles.cell}>{cell4}  </Text>
        </View>
        <View style={styles.ColE}>
            <Text style={styles.cell}>{cell5}  </Text>
        </View>
        {(onlyCatering && (
            <View style={styles.ColE}>
                <Text style={styles.cell}>   </Text>
            </View>
        ))}
    </View>
);

const AddRowSum = (cell1: string, cell2: string, cell3: string, cell4: string, cell5: string, n: number, onlyCatering: boolean) => (
    <View style={n % 2 ? styles.RowEven : styles.RowOdd} >
        <View style={styles.ColASum}>
            <Text style={styles.cell}>{cell1}</Text>
        </View>
        <View style={styles.ColBSum}>
            <Text style={styles.cell}>{cell2}</Text>
        </View>
        <View style={styles.ColCSum}>
            <Text style={styles.cell}>{cell3}</Text>
        </View>
        <View style={styles.ColDSum}>
            <Text style={styles.cell}>{cell4}</Text>
        </View>
        <View style={styles.ColESum}>
            <Text style={styles.cell}>{cell5}</Text>
        </View>
        {(onlyCatering && (
            <View style={styles.ColESum}>
                <Text style={styles.cell}>   </Text>
            </View>
        ))}
    </View>
);

const incN = () => {
    n += 1;
    return n;
}

const AddArticlesRow = (art: IArticleOrder, article: IArticle, onlyCatering: boolean) => (
    (((onlyCatering === true && article.delivery.toLowerCase() === "catering") || onlyCatering === false) && (
        AddRow(article?.name !== undefined ? article?.name : "Artikel unbekannt", article?.unit, art?.amount?.toString(), formatter.format(art.price), formatter.format(art.price !== undefined && art.amount !== undefined ? art.price * art.amount : 0), incN(), onlyCatering)
    ))
);


const ArticlesTable = (order: IOrder, allArticles: IArticle[], onlyCatering: boolean) => {
    if (order !== undefined && order.categories?.length !== 0) {
        const rows = order?.categories?.map((cat) => (
            (cat.subcategories.length !== 0 && cat.subcategories.map((subcat) => (
                (subcat.articles.length !== 0 && subcat.articles.map((art) => (
                    (art.amount !== 0 && (
                        AddArticlesRow(art, allArticles.find(i => i.key === art.articleId), onlyCatering)
                    ))
                )))
            ))
            ))
        )
        return (<Fragment>{rows}</Fragment>);
    }
};

export const OrderPdf: React.FunctionComponent<{ order: IOrder, allArticles: IArticle[], generalSettings: IGeneralSettings, onlyCatering: boolean, withDate?: boolean }> = (props) => {
    const { order, allArticles, generalSettings, onlyCatering, withDate } = props;

    const AddArticle = (art: IArticleOrder, article: IArticle, onlyCatering: boolean) => {
        if ((onlyCatering === true && article?.delivery === "Catering") || onlyCatering === false) {
            return art.price * art.amount;
        } else { return 0 }
    };

    const getSum = (order: IOrder, allArticles: IArticle[], onlyCatering: boolean) => {
        let result = 0;
        if (order !== undefined && order.categories?.length !== 0) {
            order?.categories?.map((cat) => {
                if (cat.subcategories.length !== 0) {
                    cat.subcategories.map((subcat) => {
                        if (subcat.articles.length !== 0) {
                            subcat.articles.map((art) => {
                                result += AddArticle(art, allArticles.find(i => i.key === art.articleId), onlyCatering);
                            })
                        }
                    })
                }
            })
        }
        return formatter.format(result);
    }


    return (
        <Document key="order">
            <Page style={styles.body}>
                <Text style={styles.title}>{generalSettings?.pdfaddressfield}</Text>
                <Text style={styles.empty}> </Text>
                <Text style={styles.empty}> </Text>
                {withDate !== false && (
                    <Text style={styles.textright}>{generalSettings?.pdfcity + ' ' + GetDate()}</Text>
                )}
                <Text style={styles.ordernumber}>{"Bestellnummer  " + order?.ordernumber?.toString()}</Text>
                <Text style={styles.empty}> </Text>
                {DetailsRow("Bestellung durch:", order.orderedby)}
                {DetailsRow("Telefonnummer:", order?.phone)}
                {DetailsRow("Kostenstelle:", order?.costcenter)}
                {DetailsRow("Veranstaltung:", order?.title)}
                {DetailsRow("Datum:", moment(order?.date).format("DD.MM.YYYY"))}
                {DetailsRow("Beginn Catering:", order?.start)}
                {DetailsRow("Ende Catering:", order?.end)}
                {DetailsRow("Ort:", order?.place)}
                {DetailsRow("Anzahl Personen:", order?.persons !== undefined ? order?.persons.toString() : "")}
                <Text style={styles.empty}> </Text>
                {onlyCatering !== true && order?.remarks !== undefined && order?.remarks !== "" && (
                    <>
                        <Text style={styles.empty}>Bemerkungen vom Besteller:</Text>
                        <Text style={styles.empty}>{order?.remarks}</Text>
                        <Text style={styles.empty}> </Text>
                    </>
                )}
                {onlyCatering !== true && order?.messageBesteller !== undefined && order?.messageBesteller !== "" && (
                    <>
                        <Text style={styles.empty}>Bemerkungen an den Besteller:</Text>
                        <Text style={styles.empty}>{order?.messageBesteller}</Text>
                        <Text style={styles.empty}> </Text>
                    </>
                )}
                {order?.messageCatering !== undefined && order?.messageCatering !== "" && (
                    <>
                        <Text style={styles.empty}>Bemerkungen an das Catering:</Text>
                        <Text style={styles.empty}>{order?.messageCatering}</Text>
                        <Text style={styles.empty}> </Text>
                    </>
                )}
                {onlyCatering !== true && order?.messageHausdienst !== undefined && order?.messageHausdienst !== "" && (
                    <>
                        <Text style={styles.empty}>Bemerkungen an den Hausdienst:</Text>
                        <Text style={styles.empty}>{order?.messageHausdienst}</Text>
                        <Text style={styles.empty}> </Text>
                    </>
                )}
                <Text style={styles.empty}> </Text>
                {AddHeaderRow("Produkt", "Einheit", "Stk.", "CHF", "Total", onlyCatering)}
                {ArticlesTable(order, allArticles, onlyCatering)}
                {AddRowSum("TOTAL", " ", " ", " ", getSum(order, allArticles, onlyCatering), incN(), onlyCatering)}
                <Text style={styles.vat}>Preise inkl. 7.7% MWST</Text>
            </Page>
        </Document >
    );
};




const styles = StyleSheet.create({
    empty: {
        fontSize: 12,
        textAlign: 'left'
    },
    bold: {
        fontSize: 12,
        textAlign: 'left',
        fontWeight: "bold"
    },
    textright: {
        fontSize: 12,
        textAlign: "right"
    },
    ordernumber: {
        fontSize: 14,
        textAlign: "left"
    },
    vat: {
        fontSize: 9
    },
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 12,
        textAlign: 'left'
    },
    tab: {
        width: "auto",
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    RowEven: {
        flexDirection: "row",
        borderTop: 1,
        fontSize: 12,
    },
    RowOdd: {
        flexDirection: "row",
        borderTop: 1,
        backgroundColor: "#F0F8FF",
        fontSize: 12,
    },
    RowHeader: {
        flexDirection: "row",
        backgroundColor: "lightgrey",
        alignItems: "center",
        fontSize: 12,
        borderTop: 1,
    },
    cellHeader: {
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 6,
        paddingBottom: 6,
        borderWidth: 0
    },
    ColA: {
        width: "40%",
        borderLeft: 1
    },
    ColB: {
        width: "15%",
        borderLeft: 1,
    },
    ColC: {
        width: "15%",
        textAlign: "center",
        borderLeft: 1,
    },
    ColD: {
        width: "15%",
        textAlign: "right",
        borderLeft: 1,
    },
    ColE: {
        width: "15%",
        textAlign: "right",
        borderLeft: 1,
        borderRight: 1,
    },


    RowDetailse: {
        flexDirection: "row"
    },
    Col1Details: {
        width: "20%",
        fontSize: 12
    },
    Col2Details: {
        width: "80%",
        fontSize: 12
    },
    cell: {
        borderStyle: "solid",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 6,
        paddingBottom: 6
    },
    cellDetails: {
        borderStyle: "solid"
    },

    ColASum: {
        width: "40%",
        borderLeft: 1,
        borderBottom: 1,
    },
    ColBSum: {
        width: "15%",
        borderLeft: 1,
        borderBottom: 1,
    },
    ColCSum: {
        width: "15%",
        textAlign: "center",
        borderLeft: 1,
        borderBottom: 1,
    },
    ColDSum: {
        width: "15%",
        textAlign: "right",
        borderLeft: 1,
        borderBottom: 1,
    },
    ColESum: {
        width: "15%",
        fontSize: 12,
        textAlign: "right",
        fontWeight: "heavy",
        borderLeft: 1,
        borderRight: 1,
        borderBottom: 1,
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'left',
        fontFamily: 'Times-Roman'
    },
});

