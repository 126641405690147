import React from 'react';

export const FieldFooter: React.FunctionComponent<{
  help?: string;
  errorMessage?: string;
}> = (props) => {
  return (
    <React.Fragment>
      {props.help !== undefined && <p className="help">{props.help}</p>}
      {props.errorMessage !== undefined && props.errorMessage !== '*' && (
        <span className="help is-danger">{props.errorMessage}</span>
      )}
    </React.Fragment>
  );
};
