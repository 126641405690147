import React, { useState } from "react";
import { Formik, Field } from "formik";
import appService from "../../../services/app.service";
import { Loading } from "../../Loading";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { IArticle, IOrder, IArticleOrderCategory, IGeneralSettings } from "../../../core/catering.types";
import { NavigationButton } from "../../fields/NavigationButton"
import { EventDetails } from "./EventDetails"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { OrderPdf } from "./OrderPdf"
import { pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { OrderSubCategory } from "./OrderSubCategory"
import moment from 'moment';
import { Textarea } from "../../fields/Textarea";
import graphService from "../../../services/graph.service";
import { Checkbox } from "../../fields/Checkbox";
import { OrderSummary } from "./OrderSummary";

export const getSum = (order: IOrder) => {
    let total = 0;

    if (order !== undefined && order.categories?.length !== 0) {
        order?.categories?.map((categoriesCurrentValue) => {
            categoriesCurrentValue.subcategories.map((subcategoriesCurrentValue) => {
                subcategoriesCurrentValue.articles.map((articlesCurrentValue) => {
                    if (articlesCurrentValue.amount !== undefined) {
                        total += articlesCurrentValue.amount * articlesCurrentValue.price;
                    }
                })
            })
        })
    }
    var formatter = new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' })

    return formatter.format(total);
}


export const OrderDetails: React.FunctionComponent<{ id?: string, userDisplayName?: string, isAdmin?: Boolean, redirection?: string }> = (props) => {
    const { id, isAdmin, redirection, userDisplayName } = props;
    const detailsRedirection = redirection ?? "/";
    const backButton = "/"
    const isNew = id === undefined || id === null || id === "";
    const [isLoading, setIsLoading] = useState(true);
    const [readyToOrder, setReadyToOrder] = useState(false);
    const history = useHistory();
    const [data, setData] = React.useState<{
        order: IOrder;
        allArticles: IArticle[];
        generalSettings: IGeneralSettings;
    }>({
        order: {},
        allArticles: [],
        generalSettings: {}
    });

    const isDisabled = data?.order?.status === "Archiviert" ? true : !isNew && !isAdmin;

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("*"),
        place: Yup.string().required("*"),
        date: Yup.date().typeError("Bitte ein gültiges Datum eingeben").required("*"),
        termsandcond: Yup.boolean()
            .oneOf([true], '*'),
        start: Yup.string().required("*"),
        end: Yup.string()
            .required("*")
            .test("is-greater", "Ende muss nach dem Start sein", function (value) {
                const { start } = this.parent;
                return moment(value, "HH:mm").isSameOrAfter(moment(start, "HH:mm"));
            }),
    });


    React.useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const allArticles = await appService.getArticles();
            const generalSettings = await appService.getGeneralSettings();

            let order: IOrder = null;
            if (isNew) {
                var usr = await graphService.getMe();
                let mail = usr.mail;
                if (usr?.userPrincipalName?.toLowerCase() === "aadmschmitzdi@ukbb.ch") { mail = "dsc@expertsinside.com" }
                if (usr?.userPrincipalName?.toLowerCase() === "aadmlamberpa@ukbb.ch") { mail = "pla@expertsinside.com" }
                let dn = userDisplayName;
                if (dn === undefined || dn === "") { dn = (mail === undefined || mail === "" ? usr?.userPrincipalName : mail); }

                order = {
                    title: '', place: '', charging: "internal", orderedby: dn,
                    orderedbyemail: mail, status: "Entwurf", prevstatus: "Entwurf", termsandcond: false
                }
            }
            else {
                order = await appService.getOrder(id);
            }

            setData({
                order: order,
                allArticles: allArticles,
                generalSettings: generalSettings
            });
            setIsLoading(false);
        };

        fetchData();
    }, []);

    const containsArticlesOfType = (order: IOrder, type: string) => {
        let result = false;
        if (order !== undefined && order.categories?.length !== 0) {
            order?.categories?.map((cat) => {
                cat.subcategories.map((subCat) => {
                    subCat.articles.map((art) => {
                        const article = data.allArticles.find(i => i.key === art.articleId);
                        if (art.amount !== 0 && article !== undefined && article?.delivery === type) {
                            result = true;
                        }
                    })
                })
            })
        }

        return result;
    }

    const SaveOrder = async (order: IOrder, newStatus: string) => {
        const oldStat = order.status;
        let data = { ...order, status: newStatus, prevstatus: oldStat };
        data.listsHausdienstArticle = containsArticlesOfType(data, "Hausdienst");
        data.listsCateringArticle = containsArticlesOfType(data, "Catering");

        
        const submit = async () => {
            if (data !== undefined && data !== null) {
                appService.saveOrder(data).then((modulesResults) => {
                    history.push(`${detailsRedirection}`);
                    //throw "just a test";
                }).catch((e) => {
                    alert("Es gab einen Fehler beim Speichern: " + ( e !== undefined && e.message !== undefined ? e.message : "unknown"));
                });
            }
        };

        submit();
    }


    // requester saves a new order
    const onSubmit = async (values: Readonly<IOrder | undefined>) => {
        setIsLoading(true);
        let order = { ...values };
        if (order.ordernumber == undefined) {
            order.ordernumber = await appService.getNewOrderNumber();
        }
        SaveOrder(order, "Abgeschickt");
    };

    // Admin canceles an existing order (in state Bestellt or Abgeschickt!)
    const onCancel = async (values: Readonly<IOrder | undefined>) => {
        if (window.confirm("Wollen Sie die Bestellung wirklich stornieren?")) {
            setIsLoading(true);
            let order = { ...values };
            const blob = await pdf(await myDoc(order)).toBlob();
            if (blob !== null) {
                const buffer = await blob.arrayBuffer();
                order.pdfFile = _arrayBufferToBase64(buffer);
            }
            SaveOrder(order, "Storniert");
        }
    }

    const myDoc = async (order: IOrder) => (<OrderPdf order={order} allArticles={data.allArticles} generalSettings={data.generalSettings} onlyCatering={true} />);

    // Admin confirms an order - all OK and order needs to be processed
    const onSend = async (values: Readonly<IOrder | undefined>) => {
        if (window.confirm("Wollen Sie die Bestellung wirklich abschicken?")) {
            setIsLoading(true);
            let order = { ...values };
            const blob = await pdf(await myDoc(order)).toBlob();
            if (blob !== null) {
                const buffer = await blob.arrayBuffer();
                order.pdfFile = _arrayBufferToBase64(buffer);
            }
            SaveOrder(order, "Bestellt");
        }
    }

    // Admin archives an order 
    const onArchive = async (values: Readonly<IOrder | undefined>) => {
        if (window.confirm("Wollen Sie die Bestellung wirklich archivieren?")) {
            setIsLoading(true);
            SaveOrder(values, "Archiviert");
        }
    }


    const onArchiveDelete = async (values: Readonly<IOrder>, key: string) => {
        if (id !== undefined && id !== "") {
            if (window.confirm("Wollen Sie die Order wirklich löschen?")) {
                setIsLoading(true);

                await appService.deleteOrder(values, key);
                history.push(`${detailsRedirection}`);
            }
        }
    }

    
    const _arrayBufferToBase64 = (buffer) => {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    if (isLoading === true) {
        return <Loading isLoading={true}></Loading>
    }

    return (
        <div className="section defaultSection menuSection">
            <Formik
                initialValues={data.order}
                onSubmit={onSubmit}
                validateOnMount={true}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    submitForm,
                    isSubmitting,
                    isValid,
                    dirty,
                    setFieldValue,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}
                            className="form">
                            {readyToOrder === true ? (
                                <OrderSummary articles={data.allArticles} setReadyToOrder={setReadyToOrder} getSum={getSum} />
                            ) : (
                                <React.Fragment>
                                    <h1 className="title">{isNew === true ? <React.Fragment>Neue Bestellung</React.Fragment> : <React.Fragment>Bestellung</React.Fragment>}</h1>
                                    <Tabs>
                                        <TabList>
                                            <Tab>
                                                Allgemeine Informationen
                                            </Tab>
                                            {values.categories !== undefined && values.categories.length > 0 && values.categories.map((cat: IArticleOrderCategory, indCat: number) => (
                                                <Tab key={"TAB" + indCat}>
                                                    {cat.name}
                                                </Tab>
                                            ))}
                                        </TabList>
                                        <TabPanel>
                                            <EventDetails isDisabled={isDisabled} orderStatus={values.status} order={values} />
                                        </TabPanel>
                                        {values.categories !== undefined && values.categories.length > 0 && values.categories.map((cat: IArticleOrderCategory, indCat: number) => (
                                            <TabPanel key={"OD" + indCat}>
                                                <OrderSubCategory isDisabled={isDisabled} key={"OSC" + indCat} category={cat} namespace={`categories[${indCat}]`} allArticles={data.allArticles} />
                                            </TabPanel>
                                        ))}
                                    </Tabs>
                                    <hr />
                                    <br />
                                    <h1 className="title is-4">Summe: {getSum(values)}</h1>
                                    {
                                        isNew !== true && (
                                            <PDFDownloadLink document={<OrderPdf order={values} allArticles={data.allArticles} generalSettings={data.generalSettings} onlyCatering={false} withDate={false} />} fileName="bestellung.pdf">
                                                {({ blob, url, loading, error }) =>
                                                    loading ? 'Dokument wird geladen...' : 'Bestellübersicht herunterladen'
                                                }
                                            </PDFDownloadLink>
                                        )
                                    }
                                    {
                                        isNew && (
                                            <Field
                                                name="termsandcond"
                                                label={<React.Fragment>
                                                    &nbsp;
                                                    Hiermit stimme ich den <a href="https://ukbbch.sharepoint.com/SitePages/Catering.aspx" target="_blank">allgemeinen Nutzungsbedingungen</a> zu.
                                                </React.Fragment>}
                                                component={Checkbox}
                                            />
                                        )
                                    }
                                    <div className="button-footer">
                                        {!isAdmin && (
                                            <>
                                                {isNew && (
                                                    <button
                                                        type="submit"
                                                        className={`button is-primary is-pulled-right backAndSaveButton ${isSubmitting ? "is-loading" : ""}`}
                                                        onClick={(e) => { e.preventDefault(); setReadyToOrder(true); }}
                                                        disabled={isSubmitting || !dirty || !isValid}
                                                    >Bestellung Abschicken</button>
                                                )}
                                                <NavigationButton title="Zurück" className="button is-pulled-right backAndSaveButton" relativeLocation={redirection ?? backButton} />
                                            </>
                                        )}
                                        {isAdmin && (
                                            <>
                                                <hr />
                                                <br />
                                                <h1 className="title is-4">Mitteilungen</h1>
                                                <div className="columns is-centered">
                                                    <div className="column is-one-third">
                                                        <Field
                                                            name="messageBesteller"
                                                            label="an den Besteller"
                                                            rows={3}
                                                            disabled={values?.status === "Archiviert"}
                                                            component={Textarea} />
                                                    </div>
                                                    <div className="column is-one-third">
                                                        <Field
                                                            name="messageCatering"
                                                            label="an das Catering"
                                                            rows={3}
                                                            disabled={!containsArticlesOfType(values, "Catering") || values?.status === "Archiviert"}
                                                            component={Textarea} />
                                                        {containsArticlesOfType(values, "Catering") === false && (
                                                            <h1 className="title is-7">Bestellung enthält keine Catering Artikel</h1>
                                                        )}
                                                    </div>
                                                    <div className="column is-one-third">
                                                        <Field
                                                            name="messageHausdienst"
                                                            label="an den Hausdienst"
                                                            rows={3}
                                                            disabled={!containsArticlesOfType(values, "Hausdienst") || values?.status === "Archiviert"}
                                                            component={Textarea} />
                                                        {containsArticlesOfType(values, "Hausdienst") === false && (
                                                            <h1 className="title is-7">Bestellung enthält keine Hausdienst Artikel</h1>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="columns is-vcentered">
                                                    <div className="column">
                                                        {values.status === "Archiviert" && (
                                                            <button
                                                                className={`button is-danger is-pulled-right backAndSaveButton ${isSubmitting ? "is-loading" : ""}`}
                                                                title="Archivieren"
                                                                onClick={(e) => { e.preventDefault(); onArchiveDelete(data.order, id); }}
                                                            >Löschen</button>
                                                        )}
                                                        {values.status !== "Archiviert" && (
                                                            <>
                                                                <button
                                                                    className={`button is-dark is-pulled-right backAndSaveButton ${isSubmitting ? "is-loading" : ""}`}
                                                                    title="Archivieren"
                                                                    onClick={(e) => { e.preventDefault(); onArchive(values); }}
                                                                >Archivieren</button>

                                                                <button
                                                                    className={`button is-danger is-pulled-right backAndSaveButton ${isSubmitting ? "is-loading" : ""}`}
                                                                    title="Stornieren"
                                                                    onClick={(e) => { e.preventDefault(); onCancel(values); }}
                                                                >Stornieren</button>

                                                                <button
                                                                    className={`button is-primary is-pulled-right backAndSaveButton ${isSubmitting ? "is-loading" : ""}`}
                                                                    title="Absenden"
                                                                    onClick={(e) => { e.preventDefault(); onSend(values); }}
                                                                >Bestellen</button>
                                                            </>
                                                        )}

                                                        <NavigationButton title="Zurück" className="button is-pulled-right backAndSaveButton" relativeLocation={redirection ?? backButton} />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div></React.Fragment>
                            )}


                        </form>
                    );
                }}
            </Formik>
        </div >
    );
};