import { LargeFileUploadTask, ResponseType } from "@microsoft/microsoft-graph-client";
import { User } from "@microsoft/microsoft-graph-types";
import { Auth } from "./auth/auth.service";

class GraphService {
    

    getMe = async (): Promise<User> => {
        const client = Auth.getInstance().getGraphClient();
        return (await client
            .api('me')
            .get());
    }

    
}

export default new GraphService();

