import React from "react";

function getDeepProperty(obj,propstr) {
    var prop = propstr.split('.');
    for (var i=0; i<prop.length; i++) {
      if (typeof obj === 'object')
        obj = obj[prop[i]];
    }
    return obj;
  }
  
export const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
  
    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          var valueA = getDeepProperty(a, sortConfig.key);
          var valueB = getDeepProperty(b, sortConfig.key)
          if (sortConfig.type === "Date") {
            try {
              var date1 = new Date(valueA);
              var date2 = new Date(valueB);
  
              if (sortConfig.direction === 'ascending') {
                return date1.getTime() - date2.getTime();
              }
              else {
                return date2.getTime() - date1.getTime();
              }
            }
            catch {
              return 0;
            }
          }
          else if (sortConfig.type === "Number") {
            if (valueA < valueB) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (valueA > valueB) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
          }
          else {
            if (valueA?.toLowerCase() < valueB?.toLowerCase()) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (valueA?.toLowerCase() > valueB?.toLowerCase()) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
          }
          
          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);
  
    const requestSort = (key: string, type?: string) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.type === type &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, type, direction });
    };
  
    return { items: sortedItems, requestSort, sortConfig };
  };