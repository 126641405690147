
import { IGeneralSettings, ISayHello, IServiceException } from "../core/catering.types";
import { Auth } from "./auth/auth.service";
import { IMenu, IArticle, IOrder } from "../core/catering.types";
import { IImageResponse } from "../core/app.types";

class AppService {
  fetchWithHeaders = async (
    url: string,
    options?: RequestInit
  ): Promise<Response> => {
    options = options || {};
    options.headers = options.headers || new Headers();

    let header = options.headers as Headers;
    /*
    if (header) {
      header.append("stw-location", this.location);
    }
    */
    const authService = Auth.getInstance();
    const config = authService.getConfig();
    let token = await authService.getToken([config.scopes.appScope]);
    const route = `${config.endpoints.api}/${url}`;
    //console.log(token);
    return authService.fetchWithToken(route, token, options);
  };

  public delEntity = async (entity: string, key: string): Promise<null> => {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await this.fetchWithHeaders(`${entity}`, {
          method: "DELETE",
          body: JSON.stringify({
            id: key
          }),
        });
        resolve(null);
      } catch (exception) {
        console.error(exception);
        reject();
      }
    });
  };



  // Articles
  getArticles = async (): Promise<IArticle[]> => {
    return new Promise<IArticle[]>(async (resolve, reject) => {
      const noArticles: IArticle[] = []
      try {
        return this.fetchWithHeaders("articles", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IArticle[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(noArticles);
            }
          })
          .catch((error) => {
            reject(noArticles);
          });
      }
      catch (ex) {
        reject(noArticles);
      }
    });
  };

  public getArticle = async (key: string): Promise<IArticle> => {
    return new Promise<IArticle>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`articles/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IArticle);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveArticle = async (
    client: IArticle
  ): Promise<IArticle | null> => {
    return new Promise<IArticle | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("articles", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public deleteArticle = async (key: string): Promise<IArticle> => {
    return new Promise<IArticle>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`articles/${key}`, {
          method: "DELETE",
        });

        resolve(null);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  // Menu
  getMenues = async (): Promise<IMenu[]> => {
    return new Promise<IMenu[]>(async (resolve, reject) => {
      const noArticles: IMenu[] = []
      try {
        return this.fetchWithHeaders("menu", {
          method: "GET"
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IMenu[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(noArticles);
            }
          })
          .catch((error) => {
            reject(noArticles);
          });
      }
      catch (ex) {
        reject(noArticles);
      }
    });
  };

  public getMenu = async (key: string): Promise<IMenu> => {
    return new Promise<IMenu>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`menu/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IMenu);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public getMenuByDate = async (key: string): Promise<IMenu> => {
    return new Promise<IMenu>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`menu/byDate/${key}`, {
          method: "GET",
        });
        resolve((await results.json()) as IMenu);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public saveMenu = async (
    client: IMenu
  ): Promise<IMenu | null> => {
    return new Promise<IMenu | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("menu", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  public deleteMenu = async (key: string): Promise<IMenu> => {
    return new Promise<IMenu>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`menu/${key}`, {
          method: "DELETE",
        });
        resolve(null);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };


  public copyMenu = async (client: IMenu): Promise<IMenu | null> => {
    return new Promise<IMenu | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("menu/copy", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();

        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };



  // Order
  public saveOrder = async (
    client: IOrder
  ): Promise<IOrder | null> => {
    return new Promise<IOrder | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("orders", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();
        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  getOrders = async (): Promise<IOrder[]> => {
    return new Promise<IOrder[]>(async (resolve, reject) => {
      const nolocations: IOrder[] = []

      try {
        var headers = new Headers();
        return this.fetchWithHeaders("orders", {
          method: "GET",
          headers: headers
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IOrder[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public getOrder = async (key: string): Promise<IOrder> => {
    return new Promise<IOrder>(async (resolve, reject) => {
      try {
        var headers = new Headers();
        let results = await this.fetchWithHeaders(`orders/${key}`, {
          method: "GET",
          headers: headers
        });
        resolve((await results.json()) as IOrder);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };



  getAllOrders = async (): Promise<IOrder[]> => {
    return new Promise<IOrder[]>(async (resolve, reject) => {
      const nolocations: IOrder[] = []

      try {
        var headers = new Headers();
        return this.fetchWithHeaders("ordersall", {
          method: "GET",
          headers: headers
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IOrder[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  public deleteOrder = async (client: IOrder, key: string): Promise<IOrder> => {
    return new Promise<IOrder>(async (resolve, reject) => {
      try {
        let results = await this.fetchWithHeaders(`orders/${key}`, {
          method: "DELETE",
          body: JSON.stringify(client)
        });

        resolve(null);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  
  public getNewOrderNumber = async (): Promise<number> => {
    return new Promise<number>(async (resolve, reject) => {
      try {
        var headers = new Headers();
        let results = await this.fetchWithHeaders("newordernumber", {
          method: "GET",
          headers: headers
        });
        resolve((await results.json()) as number);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };


  public uploadImage = async (file: any,): Promise<IImageResponse | null> => {
    return new Promise<IImageResponse>(async (resolve, reject) => {
      try {

        const authService = Auth.getInstance();
        const config = authService.getConfig();
        let token = await authService.getToken([config.scopes.appScope]);

        const endPointUrl = `${config.endpoints.api}/articles/upload`;

        const formData = new FormData();
        formData.append("file", file as string | Blob);

        let options: RequestInit = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
          body: formData,
        };

        let results = await fetch(endPointUrl, options);
        let result = (await results.json()) as IImageResponse;

        resolve(result);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };

  getHausdienstOrders = async (): Promise<IOrder[]> => {
    return new Promise<IOrder[]>(async (resolve, reject) => {
      const nolocations: IOrder[] = []

      try {
        var headers = new Headers();
        return this.fetchWithHeaders("hausdienstorders", {
          method: "GET",
          headers: headers
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IOrder[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  getArchiveOrders = async (): Promise<IOrder[]> => {
    return new Promise<IOrder[]>(async (resolve, reject) => {
      const nolocations: IOrder[] = []

      try {
        var headers = new Headers();
        return this.fetchWithHeaders("archiveorders", {
          method: "GET",
          headers: headers
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((jsonResult: IOrder[]) => {
                resolve(jsonResult);
              });
            } else {
              reject(nolocations);
            }
          })
          .catch((error) => {
            reject(nolocations);
          });
      }
      catch (ex) {
        reject(nolocations);
      }
    });
  };

  // PDF
  public savePDF = async (
    client: string
  ): Promise<string | null> => {
    return new Promise<string | null>(async (resolve, reject) => {
      try {
        let response = await this.fetchWithHeaders("pdfs", {
          method: "POST",
          body: JSON.stringify(client),
        });

        var results = await response.json();
        if (response.ok) {
          resolve(results);
        } else {
          reject(results);
        }
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };




  public getGeneralSettings = async (): Promise<IGeneralSettings> => {
    return new Promise<IGeneralSettings>(async (resolve, reject) => {
      try {
        var headers = new Headers();
        let results = await this.fetchWithHeaders(`generalsettings`, {
          method: "GET",
          headers: headers
        });
        resolve((await results.json()) as IGeneralSettings);
      } catch (exception) {
        console.error(exception);
        reject(null);
      }
    });
  };


  



}
export default new AppService();
