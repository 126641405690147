import React from 'react';
import { extendFieldProps } from "./Input";
import { FieldFooter } from "./FieldFooter";
import { FieldLabel } from "./FieldLabel";
import MaskedInput from "react-text-mask";

export const TimeField: React.FunctionComponent<any> = ({
    field,
    form,
    ...props
}) => {
    extendFieldProps(props, field, form);

    const {
        options,
        errorMessage,
        help,
        label,
        showRequired,
        selectedValue,
        checked,
        prefix,
        suffix,
        suffixControl,
        disabled,
        ...componentOptions
    } = props;

    const hasAddons = prefix !== undefined || suffix !== undefined || suffixControl !== undefined;
    const mainClassName = hasAddons ? "field has-addons" : "field";
    const expandedControl = hasAddons ? "control is-expanded" : "control";

    const timeNumberMask = [
        /[0-2]/,
        /[0-9]/,
        ":",
        /[0-9]/,
        /[0-9]/
    ];

    return (
        <React.Fragment>
            {hasAddons && (
                <FieldLabel label={label} showRequired={showRequired}></FieldLabel>
            )}
            <div className={mainClassName}>
                {!hasAddons && (
                    <FieldLabel label={label} showRequired={showRequired}></FieldLabel>
                )}
                {prefix !== undefined && (
                    <p className="control">
                        <a className="button is-static">{prefix}</a>
                    </p>
                )}

                <div className={expandedControl}>
                    <MaskedInput
                        {...field}
                        mask={timeNumberMask}
                        disabled={disabled}
                        placeholder={"hh:mm"}
                        type="text"
                        className={`input ${errorMessage !== undefined && errorMessage !== "" ? "is-danger" : ""}`}
                        selected={(field?.value && new Date(field?.value)) || null}
                    />
                    <FieldFooter help={help} errorMessage={errorMessage}></FieldFooter>
                </div>

                {suffixControl !== undefined && (
                    <React.Fragment>
                        {suffixControl}
                    </React.Fragment>
                )}

                {suffix !== undefined && (
                    <p className="control">
                        <a className="button is-static">{suffix}</a>
                    </p>
                )}
            </div>
        </React.Fragment>
    );
};
