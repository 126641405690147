import React, { useState } from "react";
import { IOrder } from "../../../core/catering.types"
import appService from "../../../services/app.service";
import { useSortableData } from "../../../services/sortableData.service"
import { NavigationButton } from "../../fields/NavigationButton"
import moment from "moment";
import { SortButton } from "../../SortButton"
import { Loading } from '../../Loading';
import "./OrderOverview.css"

export const getStatiClass = (val: string) => {
    let ret = "";
    switch (val.toLowerCase().trim()) {
        case "abgeschickt":
            ret = "statusSend";
            break;
        case "storniert":
            ret = "statusCanceled";
            break;
        case "bestellt":
            ret = "statusOrdered";
            break;
        case "archiviert":
            ret = "statusArchived";
            break;
    }
    return ret;
}

export const getStatusDisplayName = (val: string) => {
    let ret = val;
    switch (val.toLowerCase().trim()) {
        case "abgeschickt":
            ret = "Bestelleingang";
            break;
    }
    return ret;
}

export const OrderOverview: React.FunctionComponent<{ redirection?: string, isAdmin?: Boolean }> = (props) => {
    const { redirection, isAdmin } = props;
    const detailsRedirection = redirection ?? "/details";
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<{
        isLoading: boolean,
        data: IOrder[]
    }>({
        isLoading: true,
        data: []
    });


    const fetchData = async () => {
        setIsLoading(true);
        let data: IOrder[] = [];

        if (isAdmin) {
            data = await appService.getAllOrders();
        } else {
            data = await appService.getOrders();
        }

        setData({ isLoading: false, data: data });
        setIsLoading(false);
    };

    React.useEffect(() => {
        fetchData()
    }, []);

    const { items, requestSort, sortConfig } = useSortableData(data.data);

    if (isLoading === true)
        return <Loading isLoading={true}></Loading>

    return (
        <div className="section defaultSection">
            <h1 className="title">Bestellungen</h1>
            {isAdmin !== true && (
                <div className="columns">
                    <div className="column">
                        <NavigationButton title="Neue Bestellung" className="button is-primary is-small is-pulled-right" relativeLocation={detailsRedirection} />
                    </div>
                </div>
            )}

            <div className="table-container">
                <table className="table is-fullwidth">
                    <thead>
                        <tr>
                            <th><SortButton property="date" title="Datum" sortConfig={sortConfig} requestSort={requestSort} /></th>
                            <th><SortButton property="ordernumber" title="Bestellnummer" sortConfig={sortConfig} requestSort={requestSort} /></th>
                            <th><SortButton property="titel" title="Titel" sortConfig={sortConfig} requestSort={requestSort} /></th>
                            <th><SortButton property="location" title="Lokation" sortConfig={sortConfig} requestSort={requestSort} /></th>
                            <th><SortButton property="status" title="Status" sortConfig={sortConfig} requestSort={requestSort} /></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item) => (
                            <tr key={item.key}>
                                <td>{moment(item.date).format("DD.MM.yyyy")}</td>
                                <td>{item.ordernumber ?? "Nicht zugewiesen"}</td>
                                <td>{item.title}</td>
                                <td>{item.place}</td>
                                <td className={getStatiClass(item.status)}>{getStatusDisplayName(item.status)}</td>
                                <td className="actions">
                                    <NavigationButton className="button is-primary is-small" relativeLocation={`${detailsRedirection}/${item.key}`}>
                                        <span className="icon is-small">
                                            <i className={isAdmin ? `fas fa-edit` : `fas fa-eye`} />
                                        </span>
                                    </NavigationButton>
                                </td>
                            </tr>
                        ))}
                        {items.length === 0 && (
                            <tr>
                                <td colSpan={5}>Keine Bestellungen gefunden</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
