import React from "react";
import { Field, FieldArray } from "formik";
import { Input } from "../../fields/Input";
import { IArticle, ICategory, ISubcategory } from "../../../core/catering.types";
import { Select } from "../../fields/Select";

export const Subcategories: React.FunctionComponent<{ category: ICategory, namespace: string, allArticles: IArticle[] }> = (props) => {
    const { category, namespace, allArticles } = props;

    const articlesOptions = [{ articleId: "", text: "--- Artikel auswählen ---" }, ...allArticles.map((item) => {
        return {
            key: item.key,
            text: item.name
        };
    })];

    return (
        <FieldArray
            name={`${namespace}.subcategories`}
            render={(arrayHelpers) => (
                <React.Fragment>
                    <button className="button is-primary btnCategory"
                        onClick={(e) => {
                            e.preventDefault();
                            arrayHelpers.push({
                                name: "",
                                description: "",
                                articles: []
                            });
                        }}><i className="fas fa-plus-circle" />&nbsp;Kategorie hinzufügen</button>

                    {category.subcategories?.map((subCat: ISubcategory, indSubCat: number) => (
                        <React.Fragment>
                            <FieldArray
                                name={`${namespace}.subcategories[${indSubCat}].articles`}
                                render={(articlesArrayHelper) => (
                                    <React.Fragment>
                                        <Field
                                            name={`${namespace}.subcategories.${indSubCat}.name`}
                                            component={Input}
                                            suffixControl={
                                                <div className="control">
                                                    <button className="button"
                                                        disabled={indSubCat === 0}
                                                        onClick={(e) => { e.preventDefault(); arrayHelpers.move(indSubCat, indSubCat - 1); }}>
                                                        <i className="far fa-caret-square-up" /></button>
                                                    <button className="button"
                                                        disabled={indSubCat > category.subcategories?.length - 2}
                                                        onClick={(e) => { e.preventDefault(); arrayHelpers.move(indSubCat, indSubCat + 1); }}>
                                                        <i className="far fa-caret-square-down" /></button>
                                                    <button className="button is-danger"
                                                        onClick={(e) => { e.preventDefault(); if (window.confirm("Soll dieser Eintrag wirklich gelöscht werden?")) { arrayHelpers.remove(indSubCat); } }}><i className="fas fa-trash" /></button>
                                                    <button className="button is-primary btnArticle"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            articlesArrayHelper.push({
                                                                articleId: "",
                                                                price: ""
                                                            });
                                                        }}
                                                    ><i className="fas fa-plus-circle" /> &nbsp;Artikel hinzufügen</button>

                                                </div>
                                            }
                                        />
                                        <table className="table is-fullwidth">
                                            <thead>
                                                <tr>
                                                    <th style={{ "width": "75%" }}>Artikel</th>
                                                    <th style={{ "width": "15%" }}>Preis/CHF</th>
                                                    <th style={{ "width": "10%" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subCat.articles?.length === 0 && (
                                                    <tr>
                                                        <td colSpan={3}>Noch keine Artikel diser Kategorie zugewiesen</td>
                                                    </tr>
                                                )}
                                                {subCat.articles?.map((art: IArticle, indArt: number) => (
                                                    <React.Fragment>
                                                        <tr>
                                                            <td>
                                                                <Field
                                                                    name={`${namespace}.subcategories[${indSubCat}].articles[${indArt}].articleId`}
                                                                    disabled={false}
                                                                    className={"is-fullwidth"}
                                                                    options={articlesOptions}
                                                                    component={Select}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Field
                                                                    type={"number"}
                                                                    name={`${namespace}.subcategories[${indSubCat}].articles[${indArt}].price`}
                                                                    component={Input} /></td>
                                                            <td>
                                                                <button className="button is-danger is-small"
                                                                    onClick={(e) => { e.preventDefault(); articlesArrayHelper.remove(indArt); }}><i className="fas fa-trash" /></button>
                                                            </td>
                                                        </tr>
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                )}
                            />
                        </React.Fragment>
                    ))}
                </React.Fragment>
            )}
        ></FieldArray >
    )
};