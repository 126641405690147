import React from "react";
import { useFormikContext } from "formik";
import { IArticle, IOrder, IArticleOrderCategory } from "../../../core/catering.types";
import { EventDetails } from "./EventDetails"
import { OrderSubCategory } from "./OrderSubCategory"


export const OrderSummary: React.FunctionComponent<{ articles: IArticle[], getSum: any, setReadyToOrder: any }> = (props) => {
    const { articles, getSum, setReadyToOrder } = props;

    const { values, isSubmitting, dirty, isValid, submitForm } = useFormikContext();
    const formikValues: IOrder = values as IOrder;

    return (
        <React.Fragment>
            <h1 className="title">Die Zusammenfassung Ihrer Bestellung</h1>
            <h2 className="subtitle">NUR NOCH EINEN SCHRITT BIS ZUR BESTELLUNG</h2>
            <p>Die Übersicht zeigt Ihnen eine Zusammenfassung Ihrer vorherigen Einstellungen sowie die Inhalte Ihres aktuellen Warenkorbes mit Angaben zur Menge und Preise.</p>
            <br />
            <EventDetails isDisabled={true} orderStatus={formikValues.status} order={values} />
            <table className="table is-fullwidth orderTable">
                <thead>
                    <tr>
                        <th>Artikel</th>
                        <th style={{ "width": "15%" }}>Einheit</th>
                        <th style={{ "width": "15%" }}>Anzahl</th>
                        <th style={{ "width": "15%" }}>CHF/Einheit</th>
                        <th style={{ "width": "15%" }}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {formikValues.categories !== undefined && formikValues.categories.length > 0 && formikValues.categories.map((cat: IArticleOrderCategory, indCat: number) => (
                        <OrderSubCategory isDisabled={true} key={"OSC" + indCat} category={cat} namespace={`categories[${indCat}]`} allArticles={articles} hideUnordered={true} />
                    ))}
                </tbody>
            </table>
            <hr />
            <br />
            <h1 className="title is-4">Summe: {getSum(values)}</h1>
            <div className="button-footer">
                <button
                    type="submit"
                    className={`button is-primary is-pulled-right backAndSaveButton ${isSubmitting ? "is-loading" : ""}`}
                    onClick={(e) => { e.preventDefault(); submitForm() }}
                    disabled={isSubmitting || !dirty || !isValid}
                >Bestellung auslösen</button>
                <button
                    type="submit"
                    className={`button is-pulled-right backAndSaveButton`}
                    onClick={(e) => { e.preventDefault(); setReadyToOrder(false); }}
                    disabled={isSubmitting || !dirty || !isValid}
                >Zurück zur Bestellung</button>
            </div>
        </React.Fragment>
    );
};