import React from 'react';

export const NoAdminAlert: React.FunctionComponent<{type: string}> = (props) => {
    const { type } = props;

  return (
    <article className="message is-danger">
        <div className="message-header">
            <p>Zugriff verweigert</p>
        </div>
        <div className="message-body">
            Nur {type} können diese Seite besuchen.
        </div>
    </article>
    );
};
