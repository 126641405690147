import React from "react";
import { Field, Formik, FormikHelpers } from "formik";
import { Input } from "../../fields/Input";
import appService from "../../../services/app.service";
import { Loading } from "../../Loading";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { IMenu, IArticle } from "../../../core/catering.types";
import { NavigationButton } from "../../fields/NavigationButton"
import { DateField } from "../../fields/DateField";
import { Textarea } from "../../fields/Textarea";
import { Checkbox } from "../../fields/Checkbox";
import { Categories } from "./Categories"
import "./MenuDetails.css"

export const MenuDetails: React.FunctionComponent<{ id?: string }> = (props) => {
    const { id } = props;
    const backButton = "/admin/menues"
    const isNew = id === undefined || id === null || id === "";
    const history = useHistory();
    const [data, setData] = React.useState<{
        isLoading: boolean;
        menu: IMenu;
        allArticles: IArticle[];
    }>({
        isLoading: true,
        menu: {},
        allArticles: []
    });

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("*"),
        start: Yup.date().typeError("Bitte ein gültiges Datum angeben").required("*"),
        end: Yup.date().typeError("Bitte ein gültiges Datum angeben").required("*"),
        categories: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string().required("*"),
                    subcategories: Yup.array()
                        .of(
                            Yup.object().shape({
                                name: Yup.string().required("*"),
                                articles: Yup.array()
                                    .of(
                                        Yup.object().shape({
                                            articleId: Yup.string().required("*"),
                                            price: Yup.number().typeError("Bitte ein gültige Zahl angeben").required("*")
                                        })
                                    )
                            })
                        )
                })
            )
    });

    React.useEffect(() => {
        const fetchData = async () => {
            const allArticles = await appService.getArticles();
            const menu: IMenu = isNew ? {} : await appService.getMenu(id)

            setData({
                isLoading: false,
                menu: menu,
                allArticles: allArticles
            });
        };

        fetchData();
    }, []);

    const onSubmit = (
        values: Readonly<IMenu | undefined>,
        formikHelpers: FormikHelpers<Readonly<IMenu>>
    ) => {
        const data = values;

        const submit = async () => {
            if (data !== undefined && data !== null) {
                appService.saveMenu(data).then((modulesResults) => {
                    history.push(`${backButton}`)
                }).catch(() => {
                    alert("Es gab einen Fehler beim Speichern");
                });
            }
        };

        submit();
    };

    return (
        <div className="section defaultSection menuSection">
            <Loading isLoading={data.isLoading}></Loading>
            {data.isLoading === false && (
                <Formik
                    initialValues={data.menu}
                    onSubmit={onSubmit}
                    validateOnMount={true}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        setFieldValue,
                    }) => {
                        return (
                            <React.Fragment>
                                <form onSubmit={handleSubmit} className="form">
                                    <h1 className="title">Speisekarte</h1>
                                    <div className="columns">
                                        <div className="column is-8">
                                            <Field
                                                name="name"
                                                label="Name"
                                                component={Input} />
                                        </div>
                                        <div className="column">
                                            <Field
                                                name="start"
                                                label="Gültig ab"
                                                dateFormat="dd.MM.yyyy"
                                                component={DateField} />
                                        </div>
                                        <div className="column">
                                            <Field
                                                name="end"
                                                label="Gültig bis"
                                                dateFormat="dd.MM.yyyy"
                                                component={DateField} />
                                        </div>
                                    </div>
                                    <Field
                                        name="description"
                                        label="Beschreibung"
                                        rows={6}
                                        component={Textarea} />

                                    <Field
                                        name="isActive"
                                        label=" ist aktiv"
                                        component={Checkbox} />
                                    <hr />
                                    <Categories categories={values.categories} allArticles={data?.allArticles}></Categories>
                                    <hr />
                                    <div className="button-footer">
                                        <button
                                            type="submit"
                                            className={`button is-primary is-pulled-right backAndSaveButton${isSubmitting ? "is-loading" : ""}`}
                                            disabled={isSubmitting || !dirty || !isValid}
                                        >
                                            Speichern
                                        </button>
                                        <NavigationButton title="Zurück" className="button is-pulled-right backAndSaveButton" relativeLocation={backButton} />
                                    </div>
                                </form>
                            </React.Fragment>
                        );
                    }}
                </Formik>
            )
            }
        </div>
    );
};