import React from "react";
import { Field, useFormikContext } from "formik";
import { Input } from "../../fields/Input";
import 'react-tabs/style/react-tabs.css';
import { DateField } from "../../fields/DateField";
import { TimeField } from "../../fields/TimeField"
import { Textarea } from "../../fields/Textarea";
import appService from "../../../services/app.service";
import { Select } from "../../fields/Select";
import { IArticleOrderCategory, IArticleOrderSubcategory,IArticleOrder, IMenu, IOrder, ICategory, ISubcategory, IArticle } from "../../../core/catering.types";
import { ValueGetterKey } from "match-sorter";


export const EventDetails: React.FunctionComponent<{ isDisabled: boolean, orderStatus?: string, order: IOrder }> = (props) => {
    const { isDisabled, orderStatus, order } = props;
    const { setFieldValue } = useFormikContext();
    
    // Sets the amount value for an article in Formik
    const GetAmountFromOldOrder = (articleID: string, order: IOrder) => {
        let ret = 0;
        if (articleID !== undefined && articleID !== "" && order.categories !== undefined && order.categories.length > 0 ) {
            // peerformance -> erst array bilden dann suchen
            //let res = [];
            order.categories.forEach( cat => {
                if (cat.subcategories.length !== 0) {
                    cat.subcategories.forEach(subCat => {
                        if (subCat.articles.length !== 0) {
                            subCat.articles.forEach( art => {
                                if (art.articleId !== undefined && art.articleId !== "" && art.amount !== undefined && art.amount !== 0 && art.articleId === articleID) {
                                    //res.push({articleId: art.articleId, amount: art.amount})
                                    ret= art.amount;
                                }
                            })
                        }
                    })
                }
            })
        };
        return ret;
    }
    
    const changeDate = async (e: Date) => {
        var menu = await appService.getMenuByDate(e.toISOString());

        const oldOrder: IOrder = {...order};

        /*
        // 1st appoach - like old one - set with Menu Object !!!! wrong type
        setFieldValue("categories", menu?.categories);
        if (oldOrder?.categories !== undefined && oldOrder?.categories?.length>0) {
            if (menu.categories !== undefined && menu.categories.length > 0 ) {
                menu.categories.map((cat: ICategory, catIndex: number) => {
                    if (cat.subcategories.length !== 0) {
                        cat.subcategories.map((subcat: ISubcategory, subcatIndex: number) => {
                            if (subcat.articles.length !== 0) {
                                subcat.articles.map((art: any, artIndex: number) => {
                                    let val = GetAmountFromOldOrder(art.articleId.toString(),oldOrder);
                                    setFieldValue(`categories[${catIndex}].subcategories[${subcatIndex}].articles[${artIndex}].amount`,val);
                                })
                            }
                        })
                    }
                });
            };
        }
        */

        // 2nd - create new order object
        let newOrder: IOrder = {...order}
        // copy menu categories
        newOrder.categories = [];
        if (menu?.categories !== undefined && menu?.categories.length>0) {
            menu.categories.forEach( cat => {
                let newCat: IArticleOrderCategory = {name: cat.name, subcategories: []}
                if (cat?.subcategories !== undefined && cat?.subcategories.length>0) {
                    cat.subcategories.forEach( subCat => {
                        let newSubCat: IArticleOrderSubcategory = {name: subCat.name, articles: []}
                        if (subCat?.articles !== undefined && subCat?.articles.length>0) {
                            subCat.articles.forEach( art => {
                                let newArt: IArticleOrder = {
                                    articleId: art.articleId, 
                                    amount: GetAmountFromOldOrder(art.articleId.toString(),oldOrder),
                                    price: art.price
                                }
                                newSubCat.articles.push(newArt);
                            })
                        }
                        newCat.subcategories.push(newSubCat);
                    })
                }
                newOrder.categories.push(newCat);
            })
        }
        setFieldValue("categories", newOrder?.categories);
    }

    const charging = ["intern", "extern"];
    const unitOption = [...charging?.map((item) => {
        return {
            key: item,
            text: item
        };
    })];

    return (
        <React.Fragment>
            <div className="columns">
                <div className="column is-two-thirds">
                    <Field
                        name="title"
                        label="Titel"
                        disabled={isDisabled}
                        component={Input} />
                    <Field
                        name="remarks"
                        label="Bemerkungen"
                        disabled={isDisabled}
                        rows={18}
                        component={Textarea} />
                </div>
                <div className="column">
                    <Field
                        name="place"
                        label="Ort"
                        disabled={isDisabled}
                        component={Input} />
                    <div className="columns">
                        <div className="column">
                            <Field
                                name="date"
                                label="Datum"
                                disabled={isDisabled}
                                onChangeExecuted={changeDate}
                                dateFormat="dd.MM.yyyy"
                                component={DateField} />
                        </div>
                        <div className="column">
                            <Field
                                name="start"
                                label="Beginn"
                                disabled={isDisabled}
                                component={TimeField} />
                        </div>
                        <div className="column">
                            <Field
                                name="end"
                                label="Ende"
                                disabled={isDisabled}
                                component={TimeField} />
                        </div>
                    </div>
                    {orderStatus !== undefined && orderStatus !== "Entwurf" && (
                        <Field
                            name="orderedby"
                            label="Bestellt von"
                            disabled={true}
                            component={Input} />
                    )}
                    <Field
                        name="phone"
                        disabled={isDisabled}
                        label="Telefon bei Rückfragen"
                        component={Input} />
                    <Field
                        name="persons"
                        disabled={isDisabled}
                        label="Anzahl Personen"
                        component={Input} />
                    <Field
                        name="costcenter"
                        disabled={isDisabled}
                        label="Kostenstelle"
                        component={Input} />
                    <Field
                        name="charging"
                        disabled={isDisabled}
                        label="Verrechnung"
                        options={unitOption}
                        component={Select} />
                </div>
            </div>
        </React.Fragment>
    );
}