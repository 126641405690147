import React, { useState } from "react";
import { IMenu } from "../../../core/catering.types"
import { NoAdminAlert } from "../../NoAdminAlert"
import appService from "../../../services/app.service";
import { useSortableData } from "../../../services/sortableData.service"
import { NavigationButton } from "../../fields/NavigationButton"
import moment from "moment";
import { SortButton } from "../../SortButton"


export const MenuOverview: React.FunctionComponent = () => {
    const detailsRedirection = "/admin/menues/details";
    const [data, setData] = useState<{
        isLoading: boolean,
        data: IMenu[]
    }>({
        isLoading: true,
        data: []
    });

    const fetchData = async () => {
        setData({ isLoading: true, data: [] })
        setData({ isLoading: false, data: await appService.getMenues() });
    };


    const onDelete = async (id: string) => {
        if (window.confirm("Wollen Sie die Speisekarte wirklich löschen?")) {
            await appService.deleteMenu(id);
            fetchData();
        }
    }

    const onCopy = async (id: string) => {
        const srcMenu = await appService.getMenu(id);
        await appService.copyMenu(srcMenu);
        fetchData();
    }


    React.useEffect(() => {
        fetchData()
    }, []);

    const { items, requestSort, sortConfig } = useSortableData(data.data);

    return (
        <React.Fragment>
            <div className="section defaultSection">
                <h1 className="title">Speisekarten</h1>
                <div className="columns">
                    <div className="column">
                        <NavigationButton title="Neue Speisekarte" className="button is-primary is-small is-pulled-right" relativeLocation={detailsRedirection} />
                    </div>
                </div>

                <div className="table-container">
                    <table className="table is-fullwidth">
                        <thead>
                            <tr>
                                <th><SortButton property="name" title="Name" sortConfig={sortConfig} requestSort={requestSort} /></th>
                                <th><SortButton property="description" title="Beschreibung" sortConfig={sortConfig} requestSort={requestSort} /></th>
                                <th><SortButton property="isActive" title="Aktiv" sortConfig={sortConfig} requestSort={requestSort} /></th>
                                <th><SortButton property="start" title="Gültig ab" sortConfig={sortConfig} requestSort={requestSort} /></th>
                                <th><SortButton property="end" title="Gültig bis" sortConfig={sortConfig} requestSort={requestSort} /></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item) => (
                                <tr key={item.key}>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <td>{item.isActive === undefined || item.isActive === false ? "Nein" : "Ja"}</td>
                                    <td>{moment(item.start).format("DD.MM.yyyy")}</td>
                                    <td>{moment(item.end).format("DD.MM.yyyy")}</td>
                                    <td className="actions">
                                        <NavigationButton className="button is-primary is-small" relativeLocation={`${detailsRedirection}/${item.key}`}>
                                            <span className="icon is-small">
                                                <i className={`fas fa-edit`} />
                                            </span>
                                        </NavigationButton>
                                        <button
                                            className="button is-primary is-info is-small"
                                            title="Kopieren"
                                            onClick={(e) => { e.preventDefault(); onCopy(item.key); }}
                                        >
                                            <span className="icon is-small">
                                                <i className={`far fa-copy`}></i>
                                            </span>
                                        </button>
                                        <button
                                            className="button is-danger is-small"
                                            title="Delete"
                                            onClick={(e) => { e.preventDefault(); onDelete(item.key); }}
                                        >
                                            <span className="icon is-small">
                                                <i className={`fas fa-trash`}></i>
                                            </span>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            {items.length === 0 && (
                                <tr>
                                    <td colSpan={4}>Keine Einträge gefunden</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

        </React.Fragment>
    );
};
