import "isomorphic-fetch";

import { Client } from "@microsoft/microsoft-graph-client";
import { IAuth, IAuthLoginResponse, IConfig, IUserAccountInfo } from "./auth.types";
import { MsalAuth } from "./MsalAuth";

export class Auth implements IAuth {
  private static instance: IAuth;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() { }

  logout(): void {
    return Auth.instance.logout();
  }

  getUserAccount(): IUserAccountInfo | undefined {
    return Auth.instance.getUserAccount();
  }

  getGraphClient(): Client {
    return Auth.instance.getGraphClient();
  }

  fetchWithToken = (
    url: string,
    token: string | undefined,
    options?: RequestInit
  ): Promise<Response> => {
    return Auth.instance.fetchWithToken(url, token, options);
  };

  getToken(scopes: string[]): Promise<string | undefined> {
    return Auth.instance.getToken(scopes);
  }

  login(): Promise<IAuthLoginResponse> {
    return Auth.instance.login();
  }

  getConfig(): IConfig {
    return Auth.instance.getConfig();
  }

  public static initialize(config: IConfig) {
    Auth.instance = new MsalAuth(config);
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): IAuth {
    if (Auth.instance === null || Auth.instance === undefined) {
      throw new Error("Authentication provider must be initialized before being called over getInstance");
    }

    return Auth.instance;
  }
}
