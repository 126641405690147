import React from "react";
import { Formik, Field, FieldArray } from "formik";
import appService from "../../../services/app.service";
import { Loading } from "../../Loading";
import { IArticle, IOrder, IArticleOrderCategory, IArticleOrderSubcategory, IArticleOrder } from "../../../core/catering.types";
import { NavigationButton } from "../../fields/NavigationButton"
import { Textarea } from "../../fields/Textarea";
import { Input } from "../../fields/Input";
import { DateField } from "../../fields/DateField";
import "../Orders/OrderOverview.css"
import { getStatiClass, getStatusDisplayName } from "../Orders/OrderOverview"
import { FieldLabel } from "../../fields/FieldLabel"

export const HausdienstDetails: React.FunctionComponent<{ id?: string, isAdmin?: Boolean }> = (props) => {
    const { id, isAdmin } = props;
    const backButton = "/admin/hausdienst"
    const formatter = new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' });
    const [data, setData] = React.useState<{
        isLoading: boolean;
        order: IOrder;
        allArticles: IArticle[];
    }>({
        isLoading: true,
        order: {},
        allArticles: []
    });


    React.useEffect(() => {
        const fetchData = async () => {
            const allArticles = await appService.getArticles();
            var order: IOrder = await appService.getOrder(id)
            setData({
                isLoading: false,
                order: order,
                allArticles: allArticles
            });

        };

        fetchData();
    }, []);





    const onSubmit = () => {
    };

    const GetArticleName = (id) => {
        const item = data.allArticles.find(i => i.key === id);
        return item !== undefined ? item.name : "";
    };

    const GetArticleUnit = (id) => {
        const item = data.allArticles.find(i => i.key === id);
        return item !== undefined ? item.unit : "";
    };

    const GetArticleDelivery = (id) => {
        const item = data.allArticles.find(i => i.key === id);
        return item !== undefined ? item.delivery : "";
    };

    const isDisabled = true;

    const myStat = (status: string) => (
        <table className="table is-fullwidth">
            <tr>
                <td className={getStatiClass(status)}>{getStatusDisplayName(status)}</td>
            </tr>
        </table>
    );

    return (
        <div className="section defaultSection menuSection">
            <Loading isLoading={data.isLoading}></Loading>
            {data.isLoading === false && (
                <Formik
                    initialValues={data.order}
                    onSubmit={onSubmit}
                    validateOnMount={true}
                    enableReinitialize
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        dirty,
                        setFieldValue,
                    }) => {
                        return (
                            <React.Fragment>
                                <div className="columns">
                                    <div className="column"><h1 className="title">Hausdienst - Event Details</h1></div>
                                    <div className="column button-header">
                                        <NavigationButton title="Zurück" className="button is-pulled-right backAndSaveButton" relativeLocation={backButton} />
                                    </div>
                                </div>
                                <div className="columns">
                                    <div className="column is-two-thirds">
                                        <div className="columns">
                                            <div className="column is-two-thirds">
                                                <Field
                                                    name="title"
                                                    label="Titel"
                                                    disabled={isDisabled}
                                                    component={Input} />
                                            </div>
                                            <div className="column">
                                            <FieldLabel label={"Status"} showRequired={false}></FieldLabel>
                                                {myStat(data?.order?.status)}
                                            </div>
                                        </div>
                                        <Field
                                            name="remarks"
                                            label="Bemerkungen vom Besteller"
                                            disabled={isDisabled}
                                            rows={5}
                                            component={Textarea} />
                                        <Field
                                            name="messageHausdienst"
                                            label="Bemerkungen für den Hausdienst"
                                            disabled={isDisabled}
                                            rows={5}
                                            component={Textarea} />
                                    </div>
                                    <div className="column">
                                        <Field
                                            name="place"
                                            label="Ort"
                                            disabled={isDisabled}
                                            component={Input} />
                                        <div className="columns">
                                            <div className="column">
                                                <Field
                                                    name="date"
                                                    label="Datum"
                                                    disabled={isDisabled}
                                                    dateFormat="dd.MM.yyyy"
                                                    component={DateField} />
                                            </div>
                                            <div className="column">
                                                <Field
                                                    name="start"
                                                    label="Beginn"
                                                    disabled={isDisabled}
                                                    component={Input} />
                                            </div>
                                            <div className="column">
                                                <Field
                                                    name="end"
                                                    label="Ende"
                                                    disabled={isDisabled}
                                                    component={Input} />
                                            </div>
                                        </div>
                                        <Field
                                            name="orderedby"
                                            label="Bestellt von"
                                            disabled={true}
                                            component={Input} />
                                        <Field
                                            name="phone"
                                            disabled={isDisabled}
                                            label="Telefon bei Rückfragen"
                                            component={Input} />
                                        <Field
                                            name="persons"
                                            disabled={isDisabled}
                                            label="Anzahl Personen"
                                            component={Input} />
                                    </div>
                                    <hr />
                                    <br />
                                </div>
                                <div className="column"><h3 className="label">Die folgenden Artikel sollen geliefert werden</h3></div>
                                <div className="columns">
                                    <div className="column">
                                        <table className="table is-fullwidth orderTable">
                                            <thead>
                                                <tr>
                                                    <th>Artikel</th>
                                                    <th style={{ "width": "15%" }}>Einheit</th>
                                                    <th style={{ "width": "15%" }}>Anzahl</th>
                                                    <th style={{ "width": "15%" }}>CHF/Einheit</th>
                                                    <th style={{ "width": "15%" }}>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.order.categories !== undefined && data.order.categories.length > 0 && data.order.categories.map((cat: IArticleOrderCategory, indCat: number) => (
                                                    cat.subcategories?.map((subCat: IArticleOrderSubcategory, indSubCat: number) => (
                                                        <React.Fragment key={`SC${indSubCat}`}>
                                                            <FieldArray
                                                                name={`$.subcategories[${indSubCat}]`}
                                                                render={(subcatHelper) => (
                                                                    <React.Fragment>
                                                                        {subCat.articles?.map((art: IArticleOrder, indArt: number) => (
                                                                            (art.amount !== undefined && art.amount !== 0 && GetArticleDelivery(art.articleId) === "Hausdienst" && (
                                                                                <React.Fragment key={`SC${indSubCat}A${indArt}`}>
                                                                                    <FieldArray
                                                                                        name={`$.subcategories[${indSubCat}].articles[${indArt}]`}
                                                                                        render={(articlesHelper) => (
                                                                                            <tr>
                                                                                                <td>
                                                                                                    {GetArticleName(art.articleId)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {GetArticleUnit(art.articleId)}
                                                                                                </td>
                                                                                                <td >
                                                                                                    {art.amount ?? 0}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {formatter.format(art.price)}
                                                                                                </td>
                                                                                                <td>
                                                                                                    {formatter.format(art.price * (art.amount ?? 0))}
                                                                                                </td>
                                                                                            </tr>)}
                                                                                    />
                                                                                </React.Fragment>
                                                                            )
                                                                            )
                                                                        ))}
                                                                    </React.Fragment>
                                                                )}
                                                            />
                                                        </React.Fragment>
                                                    ))
                                                )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="button-footer">
                                    <NavigationButton title="Zurück" className="button is-pulled-right backAndSaveButton" relativeLocation={backButton} />
                                </div>
                            </React.Fragment>
                        );
                    }}
                </Formik>
            )
            }
        </div>
    );

};