import React from 'react';
import { Route } from 'react-router-dom';

export const NavigationLink: React.FunctionComponent<{ title: string, relativeLocation: string, className: string }> = (
  props
) => {
  const { title, relativeLocation, className } = props;

  const change = (history: any) => {
    history.push(`${relativeLocation}`)
  }
  return (
    <Route render={({ history }) => (
      <a className={className ==='undefined' || className ==='' ? "navbar-item": className} onClick={(e) => { e.preventDefault(); change(history); }}>{title}</a>
    )} />
  );
};
