import React, { useRef, useState } from 'react'

export const ImageUpload: React.FC<{ width: number, height?: number, onImageSelected: (file: any) => void }> = (props) => {
  const { width, height } = props;
  const [imageUrl, setImageUrl] = useState<string>();
  const hiddenFileInput = useRef<HTMLInputElement>();

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result as any)
    };
    reader.readAsDataURL(files[0]);

    if (props.onImageSelected !== undefined) {
      props.onImageSelected(files[0]);
    }
  };

  const onImageSelection = (e: any) => {
    e.preventDefault();
    if (hiddenFileInput !== undefined) {
      hiddenFileInput.current.click()
    }
  };

  return (
    <div>
      {(imageUrl === undefined) ? (
        <>
          <button className="button is-small" onClick={onImageSelection}>
            Bild auswählen
          </button>
        </>
      ) : (
        <div className="image-container" >
          <h3>Vorschau</h3>
          <img src={imageUrl} width={width} height={height} />
        </div>
      )}
      <input type="file" style={{ display: 'none' }} accept="image/*" ref={hiddenFileInput} onChange={onChange} />
    </div>
  );
};